import * as React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Container from '../components/Container'
import { Category } from '../components/Category'
import PageHeader from '../components/PageHeader'
import { breakpoints } from '../styles/variables'

const FavoritesContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
  }
`


const FavoritesPage = () => {
  return (
    <Page>
      <FavoritesContainer>
        <PageHeader title={"Omat suosikit"} />
        <Category type={'list-progress'} title="Jatka kuuntelua" screenTitle="Jatka kuuntelua" description="" padding='50' />
        <Category type={'list-favorites-series'} title="Suosikkiohjelmat" screenTitle="Suosikkiohjelmat" description="" padding='50' />
        <Category type={'list-favorites-files'} title="Suosikkijaksot" screenTitle="Suosikkijaksot" description="" padding='50' />
      </FavoritesContainer>
    </Page>
  )
}

export default FavoritesPage
